import { defineStore } from 'pinia';
import { lockBodyScroll, unlockBodyScroll } from '~/utilities/ui-utilities';
import { useSiteStore } from '~/stores/site.store';
import { useGameStore } from '~/stores/games.store';
import type {
  AuthStoreInterface,
  LogoutOptions,
} from '~/interfaces/stores/auth.store.interface';
import type { AccountModals } from '~/interfaces/dto/general/modals';
import type { IAccountBalances } from '~/interfaces/dto/wallet/response';
import type { IUserInfoExtended } from '~/interfaces/dto/auth/response';
import { useServices } from '~/composables/useServices';
import type { CasinoBonus } from '~/interfaces/dto/bonus/response';
import { useQueryHooks } from '~/utilities/query-hooks';
import { globalKeys } from 'assets/data/global-keys';
import type { IPromotionGenericWheelData } from '~/interfaces/dto/promotions/response';

export const useAuthStore = defineStore('auth', {
  state: (): AuthStoreInterface => {
    return {
      userId: '',
      currentUser: null,
      isLoggedIn: false,
      currentAccessToken: '',
      currentRefreshToken: '',
      displayAccountModal: false,
      welcomeOffer: false,
      displayBalances: true,
      userBalances: {},
      compliance: true,
      partialPostReg: true,
      accountModalPage: null,
      activeBonus: {},
      passwordResetMobileNumber: null,
      availableSpins: [],
    };
  },
  actions: {
    async initAuth() {
      const { $storageService, $authService } = useServices();
      try {
        const accessToken = $storageService.getCookie({
          key: globalKeys.authKeys.accessToken,
        });

        const refreshToken = $storageService.getUniversalStorage<string>({
          key: globalKeys.authKeys.refreshToken,
        });

        if (!!accessToken && !!refreshToken) {
          const userId = $storageService.getUniversalStorage<string>({
            key: globalKeys.authKeys.userId,
          });
          this.setAccessToken(accessToken);
          this.setRefreshToken(refreshToken);
          this.setUserId(userId);
          this.setLoggedIn();
        } else if (!!refreshToken && !accessToken) {
          this.setRefreshToken(refreshToken);
          await $authService.refreshToken();
          this.setLoggedIn();
        } else {
          this.logout();
        }
      } catch (e) {
        console.error(e);
      }
    },
    setUser(data: IUserInfoExtended) {
      try {
        this.currentUser = data;
        const { $storageService } = useServices();
        $storageService.setUniversalStorage({
          key: globalKeys.authKeys.user,
          data: JSON.stringify(data),
        });
        this.setUserId(data?.id);
      } catch (e) {
        console.error('failed to set user', e.toString());
      }
    },
    setUserBalances(data: IAccountBalances) {
      this.userBalances = data;
    },
    setBalancesVisibility() {
      const { $storageService } = useServices();
      const visibility: boolean | string = $storageService.getLocalStorage({
        key: globalKeys.authKeys.hideBalances,
      });
      this.toggleBalances(!!visibility.toString() ? Boolean(visibility) : true);
    },
    toggleBalances(display: boolean) {
      const { $storageService } = useServices();
      this.displayBalances = display;
      $storageService.setLocalStorage({
        key: globalKeys.authKeys.hideBalances,
        data: Boolean(display),
      });
    },
    async setLoggedIn() {
      const { $walletService, $authService, $toastService } = useServices();
      if (!this.currentAccessToken) this.isLoggedIn = false;
      else {
        // const getFavorites = useGetFavorites;
        const site = useSiteStore();
        site.deactivateModal();
        unlockBodyScroll();
        this.isLoggedIn = true;
        this.setBalancesVisibility();
        await $walletService.fetchBalances();
        await $authService.getUserExtended();

        const route = useRoute();
        if (!!route.query) useQueryHooks(route);

        await $toastService.toastService.fetchToasts();
        if (site.getFeatureFlags.get('myaccount.responsiblegaming')) {
          await useCheckRestrictionsService();
        }
      }
    },
    setAccessToken(access_token: string | undefined) {
      try {
        this.currentAccessToken = access_token;
        const { $storageService } = useServices();
        $storageService.setCookie({
          key: globalKeys.authKeys.accessToken,
          data: access_token,
          expiry: 1,
        });
      } catch (e) {
        console.error('failed to set access token', e.toString());
      }
    },
    setRefreshToken(refresh_token: string | undefined) {
      try {
        this.currentRefreshToken = refresh_token;
        const { $storageService } = useServices();
        $storageService.setUniversalStorage({
          key: globalKeys.authKeys.refreshToken,
          data: refresh_token,
          expiry: 365,
        });
      } catch (e) {
        console.error('failed to set refresh token', e.toString());
      }
    },
    setUserId(userId: string | undefined) {
      this.userId = userId;
      const { $storageService } = useServices();
      $storageService.setUniversalStorage({
        key: globalKeys.authKeys.userId,
        data: userId,
      });
      window?.dtrum?.identifyUser(userId);
    },
    setWelcomeOffer(flag: boolean) {
      this.welcomeOffer = flag;
    },
    setPartialPostReg(flag: boolean) {
      this.partialPostReg = flag;
    },
    setDocumentVerificationStatus(flag: boolean) {
      this.compliance = flag;
    },
    logout(
      { redirect, path, reload }: LogoutOptions = {
        redirect: false,
        path: '/',
        reload: false,
      },
    ) {
      const { $storageService } = useServices();

      Object.values(globalKeys.authKeys).forEach((key) => {
        $storageService.removeUniversalStorage({ key });
      });
      this.toggleAccountModal(false);
      const games = useGameStore();
      games.clearRecentlyPlayed();
      games.clearFavourites();
      unlockBodyScroll();
      this.$reset();

      if (!!window?.navigator) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          for (const registration of registrations) {
            if (
              registration.active.scriptURL.includes('firebase-messaging-sw')
            ) {
              registration.unregister();
            }
          }
        });
      }

      if (redirect) {
        window.location.href = !!path ? path : '/';
        return;
      }
      if (reload) {
        const router = useRouter();
        router.go(0);
        return;
      }
    },
    toggleAccountModal(active: boolean) {
      this.displayAccountModal = active;
      const { $toastService } = useServices();
      if (!active) {
        $toastService.toastService.fetchToasts();
      }
      if (active) lockBodyScroll();
      else unlockBodyScroll();
    },
    setAccountModalPage(page: AccountModals) {
      if (!!page) this.toggleAccountModal(true);
      this.accountModalPage = page;
    },
    setActiveBonus(bonus: CasinoBonus) {
      this.activeBonus = bonus;
    },
    setPasswordResetMobileNumber(mobileNumber: string) {
      this.passwordResetMobileNumber = mobileNumber;
    },
    setAvailableSpins(spins: Partial<IPromotionGenericWheelData>[]) {
      this.availableSpins = spins;
    },
  },
  getters: {
    loggedIn(state): boolean {
      return state?.isLoggedIn;
    },
    user(state): Partial<IUserInfoExtended> {
      return state?.currentUser;
    },
    access_token(state): string {
      return state?.currentAccessToken;
    },
    user_id(state): string {
      return state?.userId;
    },
    getWelcomeOffer(state): boolean {
      return state?.welcomeOffer;
    },
    accountBalances(state): IAccountBalances {
      return state.userBalances;
    },
    displayAccountBalances(state): boolean {
      return state.displayBalances;
    },
    accountModal(state): boolean {
      return state.displayAccountModal;
    },
    getDocumentVerificationStatus(state) {
      return state.compliance;
    },
    getAccountModalPage(state): AccountModals {
      return state.accountModalPage;
    },
    getIdDocumentStatus(state) {
      return state.currentUser.proofOfIdDocumentStatus;
    },
    getBankDocumentStatus(state) {
      return state.currentUser.proofOfBankDocumentStatus;
    },
    getSupportingDocumentStatus(state) {
      return state.currentUser.proofOfResidenceDocumentStatus;
    },
    getComplianceStatus(state) {
      return state.currentUser.compliance?.complianceStatus;
    },
    getIsUserCompliant(state) {
      return (
        state.currentUser?.compliance?.complianceStatus >
        state.currentUser?.compliance?.requiredComplianceStatus
      );
    },
    getPartialPostReg(state) {
      return state.partialPostReg;
    },
    getActiveBonus(state) {
      return state.activeBonus;
    },
    getAvailableSpins(state) {
      return state.availableSpins;
    },
    getCurrentSpin(state) {
      return (
        state.availableSpins?.filter(
          // (as) => as.isActive && as.unusedSpinCount > 0,
          (as) => as?.unusedSpinCount > 0,
        )?.[0] || null
      );
    },
  },
});
