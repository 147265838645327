import type { IMarketingSessionAddRequest } from '~/interfaces/dto/marketing/request';
import { useStorageService } from '~/composables/services/useStorageService';
import { useMarketingService } from '~/composables/services/useMarketingService';
import { useSiteStore } from '~/stores/site.store';
import { globalKeys } from 'assets/data/global-keys';
import type { IInfoResponse } from '~/interfaces/dto/marketing/response';

export const useAddMarketingSession = async () => {
  const site = useSiteStore();
  const route = useRoute();
  const { query } = route;

  const $storageService = useStorageService();
  const $marketingService = useMarketingService();

  const { deviceInfo, IpAddress } =
    $storageService.getUniversalStorage<IInfoResponse>({
      key: globalKeys.siteConfigKeys.deviceInfo,
    });

  if (
    !$storageService.getSessionStorage({
      key: globalKeys.marketingKeys.marketingSession,
    })
  ) {
    const regionId: number = 13;
    const platformIds = {
      mobile: 1,
      tablet: 1,
      desktop: 2,
      ios: 3,
      android: 4,
      huawei: 5,
    };

    const appType =
      String(query?.appType) ||
      (deviceInfo.IsMobile || deviceInfo.IsTablet ? 'mobile' : 'desktop');

    const sessionData: IMarketingSessionAddRequest = {
      platformID: platformIds[appType.toLowerCase()],
      regionID: regionId,
      vendorAccountID: $storageService.getLocalStorage({
        key: globalKeys.authKeys.userId,
      }),
      dataFree: false,
      bannerTag: $storageService.getCookie({
        key: globalKeys.marketingKeys.bannerTag,
      }),
      queryParamString: $storageService.getCookie({
        key: globalKeys.marketingKeys.originalQueryString,
      }),
      ipAddress: IpAddress,
      countryCode: site.getRegionCode,
      userAgent: navigator.userAgent,
      sessionTrackingToken: '',
      sessionMetaData: [
        {
          cookieName: globalKeys.marketingKeys.referrerBannerTag,
          cookieValue: $storageService.getCookie({
            key: globalKeys.marketingKeys.referrerBannerTag,
          }),
          cookieDomain: '',
        },
        {
          cookieName: globalKeys.marketingKeys.masterToken,
          cookieValue: $storageService.getCookie({
            key: globalKeys.marketingKeys.masterToken,
          }),
          cookieDomain: '',
        },
        {
          cookieName: globalKeys.marketingKeys.sessionToken,
          cookieValue: $storageService.getCookie({
            key: globalKeys.marketingKeys.sessionToken,
          }),
          cookieDomain: '',
        },
      ],
      ...deviceInfo,
    };

    $marketingService.setMarketingSession(sessionData);
  }
};
