import { BaseService } from '~/services/base.service';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import type { FetchContext, FetchResponse } from 'ofetch';
import type { IPromotionGenericWheel } from '~/interfaces/dto/promotions/response';

export const usePromotionService = () => {
  const appConfig = useRuntimeConfig();
  const base = new BaseService(appConfig.public.promotions);
  const authStore = useAuthStore();
  const site = useSiteStore();

  const getGenericWheelCount = async (): Promise<IPromotionGenericWheel> => {
    if (!authStore.access_token)
      return {
        success: true,
        data: [
          {
            promotionId: 0,
            unusedSpinCount: 0,
          },
        ],
        message: 'string',
        statusCode: 0,
        errors: ['string'],
      };
    const requestUrl = base.requestUrl({
      path: `api/v1/GenericWheel/GetUserSpinSummary`,
    });
    return await $fetch<IPromotionGenericWheel>(requestUrl, {
      headers: {
        Authorization: `Bearer ${authStore.access_token}`,
      },
      onResponse(
        context: FetchContext & { response: FetchResponse<ResponseType> },
      ): Promise<void> | void {
        authStore.setAvailableSpins(context.response._data.data);
      },
    });
  };

  const addOptInTag = async (tagName: string = 'jpcwelcomeoffer') => {
    try {
      const apiBase = new BaseService(appConfig.public.base);
      const requestUrl = apiBase.requestUrl({
        path: `api/v2/Tagging/AddOrEditTagOnVuvuzelaAccount`,
      });
      const request = {
        accountId: authStore.user_id,
        tagName: tagName,
        value: new Date().toISOString(),
      };

      return await $fetch<boolean>(requestUrl, {
        method: 'post',
        query: {
          regionCode: site.regionCode,
        },
        body: request,
        headers: {
          Authorization: `Bearer ${authStore.access_token}`,
        },
        onResponseError({ response }) {
          console.error(response);
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return { getGenericWheelCount, addOptInTag };
};
