import { BaseService } from '~/services/base.service';
import { useSiteStore } from '~/stores/site.store';
import type { IConfigResponse } from '~/interfaces/dto/config/response';
import { configMapper } from '~/utilities/config-mapper';
import { remapSitemap } from '~/utilities/sitemap-mapper';
import { defaultLocales } from '~/assets/data/config';
import { useLocaleService } from './useLocaleService';
import { useSitemapService } from './useSitemapService';

export const useConfigService = () => {
  const appConfig = useRuntimeConfig();
  const base = new BaseService(appConfig.public.config);
  const siteStore = useSiteStore();
  const localeStore = useLocaleStore();

  const $sitemapService = useSitemapService();
  const $localeService = useLocaleService();
  const fetchConfig = async (countryCode: string) => {
    const requestUrl: string = base.requestUrl({
      path: `cron/appsettings/jackpotcity/${countryCode}`,
    });
    const { setCache, getCache } = await useCache(`config-${countryCode}`);
    if (!!getCache) {
      siteStore.setConfig(getCache);
      return getCache;
    }

    return await $fetch<IConfigResponse>(requestUrl, {
      keepalive: true,
      onResponse({ response }) {
        const remappedConfig = configMapper(response._data);
        siteStore.setConfig(remappedConfig);
        setCache(remappedConfig);
      },
      onRequestError(context) {
        console.error('[REQUEST ERROR]:', context);
      },
      onResponseError(context) {
        console.error('[RESPONSE ERROR]:', context);
      },
    });
  };
  const fetchRedirects = async (countryCode: string) => {
    const requestUrl: string = base.requestUrl({
      path: `cron/redirects/jackpotcity/${countryCode}`,
    });
    const { setCache, getCache } = await useCache<
      { fromLink: string; toLink: string }[]
    >(`redirects-${countryCode}`);

    if (!!getCache) {
      siteStore.setRedirects(getCache);
      return getCache;
    }

    return await $fetch<{ fromLink: string; toLink: string }[]>(requestUrl, {
      keepalive: true,
      onResponse({ response }) {
        siteStore.setRedirects(response._data);
        setCache(response._data);
      },
      onRequestError(context) {
        console.error('[REQUEST ERROR]:', context);
      },
      onResponseError(context) {
        console.error('[RESPONSE ERROR]:', context);
      },
    });
  };
  const fetchFullConfig = async (countryCode: string, localeCode = 'en-US') => {
    const transformAndSetState = (data) => {
      if (!!data?.appsettings) {
        const remappedConfig = configMapper(data?.appsettings);
        siteStore.setConfig(remappedConfig);
      }

      //sitemap
      if (!!data?.sitemaps?.sitemap) {
        const remappedSitemap = remapSitemap(data?.sitemaps?.sitemap);
        const sitemapHost: string = data?.sitemaps?.request?.host;
        const hostEnvironment = sitemapHost.match(/dv|stg/);
        const cmsHost = !!hostEnvironment
          ? `cms1.jpc.africa.${hostEnvironment[0]}`
          : 'cms1.jpc.africa';
        siteStore.setSitemapHost(sitemapHost);
        siteStore.setCmsHost(cmsHost);
        siteStore.setSiteMap(remappedSitemap);
      }
      //redirects
      if (!!data?.redirects) {
        siteStore.setRedirects(data?.redirects);
      }
      if (!!data?.locales) {
        localeStore.setLocale(data?.locales);
      }
    };

    const requestUrl: string = base.requestUrl({
      path: `cron/app-data/${countryCode}/webdesktop/jackpotcity/${localeCode}`,
      lowercaseTransform: false,
    });
    const { setCache, getCache } = await useCache(
      `full-config-${countryCode}-${localeCode}`,
    );
    if (!!getCache) {
      transformAndSetState(getCache);
      return getCache;
    }

    return await $fetch<IConfigResponse>(requestUrl, {
      keepalive: true,
      async onResponse({ response }) {
        const controlValidation = {
          appsettings: {
            fallback: () => fetchConfig(countryCode),
            value: !!response._data?.appsettings,
          },
          sitemaps: {
            fallback: () => $sitemapService.fetchSitemap(countryCode),
            value: !!response._data?.sitemaps,
          },
          locales: {
            fallback: () =>
              $localeService.fetchLocale(defaultLocales[countryCode]),
            value: !!response._data?.locales,
          },
        };
        const failedValidations = Object.values(controlValidation)
          .map((val) => {
            if (!val.value) return val.fallback();
          })
          .filter((fv) => !!fv);

        transformAndSetState(response._data);
        if (failedValidations.length < 1) {
          await setCache(response._data);
        }
      },
      onRequestError(context) {
        console.error('[REQUEST ERROR]:', context);
      },
      onResponseError(context) {
        console.error('[RESPONSE ERROR]:', context);
      },
    }).catch((e) => {
      console.error('Could not retrieve config', e);
    });
  };

  return { fetchConfig, fetchRedirects, fetchFullConfig };
};
