export function lockBodyScroll(fixBody: boolean = false) {
  if (!!document && typeof document !== 'undefined') {
    const body = document?.body;
    body.classList.add('overflow-y-scroll');
    body.classList.add('w-full');
    if (fixBody) body.classList.add('fixed');
  }
}
export function unlockBodyScroll() {
  if (!!document && typeof document !== 'undefined') {
    const body = document?.body;
    body.classList.remove('fixed');
    body.classList.remove('overflow-y-scroll');
    body.classList.remove('w-full');
  }
}
export function boundaryErrorReporter({
  file,
  componentBoundary,
  err,
}: {
  file: string;
  componentBoundary: string;
  err: unknown;
}) {
  console.error({ type: `UI Boundary Error`, file, componentBoundary, err });
}
