const authKeys = {
  accessToken: 'auth:access_token',
  refreshToken: 'auth:refresh_token',
  userId: 'auth:userId',
  user: 'auth:user',
  passwordResetNumber: 'auth:password-reset-number',
  hideBalances: 'show-balances',
};
const promotionKeys = {
  bigcityWheel: 'BigCityLifeWheel:SpinCount',
  genericWheel: 'generic-wheel:spin-count',
};
const marketingKeys = {
  bannerTag: 'BTAGCOOKIE',
  referrerBannerTag: 'REFERRERBTAGCOOKIE',
  masterToken: 'MT',
  sessionToken: 'ST',
  marketingSession: 'MkSession',
  originalQueryString: 'OriginalQueryString',
};

const siteConfigKeys = {
  locale: 'locale',
  deviceInfo: 'device-info',
  buildTimestamp: 'buildTimeStamp',
  environment: 'environment',
};

const developerConfigKeys = {
  debugRegion: 'debug-region',
  debugEnvironment: 'debug-environment',
};
export const globalKeys = {
  authKeys,
  promotionKeys,
  siteConfigKeys,
  marketingKeys,
  developerConfigKeys,
};
