import { useAuthService } from './services/useAuthService';
import { useSiteStore } from '~/stores/site.store';
import { useRgStore } from '~/stores/responsibleGaming.store';
import type {
  IUserGetLimitsType,
  IULResponseItem,
  IUserLimitResponseType,
  IRestrictionType,
  IFeatureLockedResponse, 
  ISelfExclusionReasons,
  IBrandGetLimitsType,
  IBrandLimitResponseType,
  IWagerProgress,
  IWagerProgressType
} from '~/interfaces/dto/player/response';
type IActionType = 'deposit' | 'withdrawal' | 'gameplay' | 'responsible-gaming'; 

interface IFeatureState {
  param: string;
  state: string;
}

export const checkLockedStatus = async (): Promise<void> => {
  const rg = useRgStore();
  const site = useSiteStore();
  const featureLockedPayloads: IFeatureState = {
    param: 'allowWithdrawal',
    state: 'setAllowWithdrawal'
  }
  if (rg.getAccountLockStatus) {
    useIsFeatureLocked(featureLockedPayloads);
    useFeatureLockedModals();
  }
  const rgWager: boolean = site.getFeatureFlags.get('responsiblegaming.wagerlimit');
  const rgSessionLimit: boolean = site.getFeatureFlags.get('responsiblegaming.sessionlimit');
  if (rgWager || rgSessionLimit) {
    if (rg.getCurrentSessionLimit !== null) {
      sessionTimeout();
    }
  }
}
export const useIsFeatureLocked = async (param: IFeatureState): Promise<[
]> => {
  const rg = useRgStore();
  const $authService = useAuthService();
  const payload = { [param.param]: true };
  const result: IFeatureLockedResponse = await $authService.isFeatureLocked(payload);
  return rg[param.state](result?.data);
}
export const useCheckRestrictionsService = async (): Promise<void> => {
  useGetUserLimits();
  useGetUserLocks();
  useGetExclusions();
  useFetchBrandLimitsLocks();
  useGetWagerProgress();
};
export const useFetchBrandLimitsLocks = (
): void => {
  useGetBrandLimits();
  useGetBrandLocks();
};
export const useFeatureLockedModals = (): void => {
  const rg = useRgStore();
  const site = useSiteStore();
  if ((!rg.allowWithdrawal) || (!rg.allowPayout)) {
    site.activateModal('accountRestricted');
  };
  if (rg.accountLocked) {
    site.activateModal('accountLocked');
  }
}

export const useGetUserLimits = async (
  action?: IActionType,
  data?: any
): Promise<void> => {
  const rg = useRgStore();
  const $authService = useAuthService();
  const userLimits: Partial<IUserGetLimitsType> = await $authService.getUserLimits();
  const activeUserLimits: IUserLimitResponseType[] = userLimits?.data?.restrictionResponses?.filter(
    (limit: IUserLimitResponseType): boolean => limit.isActive === true
  );
  if (activeUserLimits) {
    rg.setUserLimits(activeUserLimits);
  }
};
export const useGetBrandLimits = async (
  action?: IActionType,
  data?: any
): Promise<void> => {
  const rg = useRgStore();
  const $authService = useAuthService();
  const brandLimits: Partial<IBrandGetLimitsType> = await $authService.getBrandLimits();
  const activeBrandLimits: IBrandLimitResponseType[] = brandLimits?.data?.restrictionTypeResponses;
  rg.setBrandLimits(activeBrandLimits);
};
export const useGetBrandLocks = async (
  action?: IActionType,
  data?: any
): Promise<void> => {
  const rg = useRgStore();
  const $authService = useAuthService();
  const userLocks: Partial<IBrandGetLimitsType> = await $authService.getBrandLocks();
  const activeBrandLocks: IBrandLimitResponseType[] = userLocks?.data?.restrictionTypeResponses;
  rg.setBrandLocks(activeBrandLocks);
};

export const useGetWagerProgress = async (
  action?: IActionType,
  data?: any
): Promise<void> => {
  const rg = useRgStore();
  const $authService = useAuthService();
  const durations: Partial<IWagerProgress> = await $authService.getWagerProgress();
  const durationProgress: IWagerProgressType[] = durations?.data?.wagerLimits;
  rg.setWagerDuration(durationProgress);
}
export const sessionTimeout = (redirect?: boolean): void => {
  const rg = useRgStore();
  const site = useSiteStore();
  const sessionLimit = ref<number>(rg.getCurrentSessionLimit);
  const totalMins = ref<number>();
  if (sessionLimit.value > 0) {
    totalMins.value = (sessionLimit.value * 60);
  }
  if (!rg.timerSet) {
    rg.setTimerSet();
    const timer = setInterval((): void => {
      if (totalMins.value > 0) {
        totalMins.value -= 1;
      } else {
        rg.setTimerSet();
        clearInterval(timer);
      }
      if (totalMins.value === 0) {
        site.activateModal('sessionLimitExceeded');
      }
    }, 1000);
  }
}

export const wagerProgress = (name: string): number => {
  const rg = useRgStore();
  let updatedProgress: number;
  if (rg.getWagerDuration === null) {
    updatedProgress = 0;
  } else {
    rg.getWagerDuration.filter((duration: IWagerProgressType): void => {
      if (duration?.limitName.startsWith(name)) {
        updatedProgress = duration?.progressTowardThreshold;
      }
    })
  }
  return updatedProgress;
}
export const useGetExclusions = async (
  action?: IActionType,
  data?: any
): Promise<void> => {
  const rg = useRgStore();
  const $authService = useAuthService();
  const exclusion: Partial<ISelfExclusionReasons> = await $authService.getExclusions();
  rg.setExclusions(exclusion?.data);
};
export const useGetUserLocks = async (
  action?: IActionType,
  data?: any
): Promise<void> => {
  const rg = useRgStore();
  const $authService = useAuthService();
  const userLocks: Partial<IUserGetLimitsType> = await $authService.getUserLocks();
  const activeUserLocks: IUserLimitResponseType[] = userLocks?.data?.restrictionResponses?.filter(
    (lock: IUserLimitResponseType): boolean => lock.isActive === true
  );
  if (activeUserLocks) {
    rg.setUserLocks(activeUserLocks);
  }
}
export const getAddOrUpdate = (id: string, type: 'lock' | 'limit'): boolean => {
  const rg = useRgStore();
  let result: {};
  if (type === 'limit') {
    if (rg.userLimits === null) {
      return false
    } else {
      result = rg.userLimits.filter((limit: Partial<IUserLimitResponseType>): boolean => limit?.restrictionType?.restrictionTypeId === id);
    }
  } else {
    if (rg.userLocks === null) {
      return false
    } else {
      result = rg.userLocks.filter((lock: IUserLimitResponseType): boolean => lock?.restrictionType?.restrictionTypeId === id);
    }
  }
  return Object.keys(result).length !== 0
}
export const useGetRestrictionObject = (mapping: string, type: 'lock' | 'limit', source: 'user' | 'brand'): IULResponseItem => {
  const rg = useRgStore();
  let restriction;
  let state;
  if (type === 'lock') {
    state = source === 'brand' ? rg.brandLocks : rg.userLocks;
  } else {
    state = source === 'brand' ? rg.brandLimits : rg.userLimits;
  }
  if (state !== null) {
    state.forEach((limit: IRestrictionType) => {
      if (limit?.restrictionType?.mappingCode === mapping) {
        restriction = limit;
      } 
    });
  }
  return restriction
}